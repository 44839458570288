var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c("div", [
                _vm.ok
                  ? _c(
                      "div",
                      [
                        _c("validation-observer", {
                          ref: "observer",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ invalid, handleSubmit }) {
                                  return [
                                    _c(
                                      "b-form",
                                      {
                                        on: {
                                          submit: function ($event) {
                                            $event.preventDefault()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card",
                                          {
                                            attrs: {
                                              header: "Dati Identificativi",
                                              "header-tag": "header",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              [
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          vid: "codice",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].codice.label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .codice.label,
                                                          placeholder:
                                                            "Inserisci un codice",
                                                          rules: {
                                                            required: true,
                                                          },
                                                          maxlength: "4",
                                                          disabled: true,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .codice,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "codice",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].codice",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          vid: "descrizione",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].descrizione.label,
                                                          label: "Descrizione",
                                                          placeholder:
                                                            "Inserisci una descrizione",
                                                          maxlength: "50",
                                                          rules: {
                                                            required: true,
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .descrizione,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "descrizione",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].descrizione",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "tipo_di_movimento",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].tipo_di_movimento
                                                            .label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .tipo_di_movimento
                                                              .label,
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .tipo_di_movimento
                                                              .options,
                                                          taggable: false,
                                                          multiple: false,
                                                          closeOnSelect: true,
                                                          rules:
                                                            _vm.getRules(
                                                              "tipo_di_movimento"
                                                            ),
                                                          disabled:
                                                            _vm.hasCashEntries,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .tipo_di_movimento,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "tipo_di_movimento",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].tipo_di_movimento",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "tipologia",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].tipologia.label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .tipologia.label,
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .tipologia
                                                              .options,
                                                          taggable: false,
                                                          multiple: false,
                                                          closeOnSelect: true,
                                                          rules:
                                                            _vm.getRules(
                                                              "tipologia"
                                                            ),
                                                          disabled:
                                                            _vm.hasCashEntries,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .tipologia,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "tipologia",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].tipologia",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "causale_collegata",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].causale_collegata
                                                            .label,
                                                          label:
                                                            "Causale autom. collegata",
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .causale_collegata
                                                              .options,
                                                          taggable: false,
                                                          multiple: false,
                                                          closeOnSelect: true,
                                                          rules: {
                                                            integer: true,
                                                          },
                                                          disabled:
                                                            _vm.hasCashEntries,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .causale_collegata,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "causale_collegata",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].causale_collegata",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "id_conto_prima_nota_default",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ]
                                                            .id_conto_prima_nota_default
                                                            .label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .id_conto_prima_nota_default
                                                              .label,
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .id_conto_prima_nota_default
                                                              .options,
                                                          taggable: false,
                                                          multiple: false,
                                                          closeOnSelect: true,
                                                          rules: _vm.getRules(
                                                            "id_conto_prima_nota_default"
                                                          ),
                                                          disabled:
                                                            _vm.hasCashEntries,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .id_conto_prima_nota_default,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "id_conto_prima_nota_default",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].id_conto_prima_nota_default",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "include_produttore",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].include_produttore
                                                            .label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .include_produttore
                                                              .label,
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .include_produttore
                                                              .options,
                                                          taggable: false,
                                                          multiple: false,
                                                          closeOnSelect: true,
                                                          rules: {
                                                            required: true,
                                                          },
                                                          disabled:
                                                            _vm.hasCashEntries,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .include_produttore,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "include_produttore",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].include_produttore",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "include_compagnia",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].include_compagnia
                                                            .label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .include_compagnia
                                                              .label,
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .include_compagnia
                                                              .options,
                                                          taggable: false,
                                                          multiple: false,
                                                          closeOnSelect: true,
                                                          rules: {
                                                            required: true,
                                                          },
                                                          disabled:
                                                            _vm.hasCashEntries,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .include_compagnia,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "include_compagnia",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].include_compagnia",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.customInputs[_vm.rep]
                                                    .length,
                                                expression:
                                                  "customInputs[rep].length",
                                              },
                                            ],
                                            staticClass: "mt-2",
                                          },
                                          [
                                            _c(
                                              "b-card",
                                              {
                                                attrs: {
                                                  header:
                                                    "Attributi personalizzati",
                                                  "header-tag": "header",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-card-text",
                                                  [
                                                    _c("custom-inputs", {
                                                      attrs: {
                                                        customInputs:
                                                          _vm.customInputs[
                                                            _vm.rep
                                                          ],
                                                        beForm:
                                                          _vm.beForm[_vm.rep],
                                                        beRules:
                                                          _vm.beRules[_vm.rep],
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form[_vm.rep],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            _vm.rep,
                                                            $$v
                                                          )
                                                        },
                                                        expression: "form[rep]",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "py-2 mb-4" },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  type: "button",
                                                  disabled: invalid,
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return handleSubmit(
                                                      _vm.onSubmit
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Salva\n              "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "float-right",
                                                attrs: {
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$router.back()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-icon-chevron-double-left",
                                                  {
                                                    attrs: {
                                                      "font-scale": "0.9",
                                                    },
                                                  }
                                                ),
                                                _vm._v("Torna indietro"),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            593249117
                          ),
                        }),
                      ],
                      1
                    )
                  : _c("div", [
                      _vm._v(
                        "\n        " + _vm._s(_vm.errorMessage) + "\n      "
                      ),
                    ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }